import { Component, OnInit, OnDestroy, ComponentRef } from "@angular/core";
import { Router, ActivationEnd } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { Constants } from "../../../../constants/constants";
import { ModalService } from "../../../../components/shared/modals/modal.service";
import { RolesService } from "../roles.service";
import { Role } from "src/app/models/shared";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { RolesPermissionsColumnComponent } from "src/app/components/shared/table-list/tables-components/roles-permissions-column/roles-permissions-column.component";
import { TourSteps } from "src/app/constants/tour-steps";
import { TourService } from "ngx-ui-tour-md-menu";

@Component({
    selector: "app-role-list",
    templateUrl: "./role-list.component.html"
})
export class RoleListComponent implements OnInit, OnDestroy {
    loading = true;
    refreshing = false;
    roles: Role[] = [];
    selectedRoleID: number;
    selectedRows: Role[] = [];

    urls = Constants.urls;

    private routeSubscription: Subscription;
    private rolesSubscription: Subscription;
    private rolesBS$ = new BehaviorSubject<Role[]>([]);

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 280,
            visible: true,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<Role>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: (row: Role): string => row.name,
            textValue: (row: Role): string => row.name
        },
        {
            header: this.translate.instant("TAG"),
            columnDef: "access_tag",
            width: 200,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<Role>(
                row => row.resourceTag.name,
                row => row.resourceTag.name,
                () => true
            ),
            sortBy: (row: Role): string => row.resourceTag.name,
            textValue: (row: Role): string => row.resourceTag.name
        },
        {
            header: this.translate.instant("RESOURCES"),
            columnDef: "resources",
            width: 50,
            visible: true,
            component: RolesPermissionsColumnComponent,
            assignComponentsInputs: (compRef: ComponentRef<RolesPermissionsColumnComponent>, role: Role) => {
                const componentInstance = compRef.instance;
                componentInstance.view = role._frontData.resources_read;
                componentInstance.edit = role._frontData.resources_write;
                componentInstance.notify = role._frontData.resources_notify;
            }
        },
        {
            header: this.translate.instant("CHANNELS"),
            columnDef: "channels",
            width: 50,
            visible: true,
            component: RolesPermissionsColumnComponent,
            assignComponentsInputs: (compRef: ComponentRef<RolesPermissionsColumnComponent>, role: Role) => {
                const componentInstance = compRef.instance;
                componentInstance.view = role._frontData.channels_read;
                componentInstance.edit = role._frontData.channels_write;
                componentInstance.notify = role._frontData.channels_notify;
            }
        },
        {
            header: this.translate.instant("SOURCES"),
            columnDef: "sources",
            width: 50,
            visible: true,
            component: RolesPermissionsColumnComponent,
            assignComponentsInputs: (compRef: ComponentRef<RolesPermissionsColumnComponent>, role: Role) => {
                const componentInstance = compRef.instance;
                componentInstance.view = role._frontData.sources_read;
                componentInstance.edit = role._frontData.sources_write;
                componentInstance.notify = role._frontData.sources_notify;
            }
        },
        {
            header: this.translate.instant("TARGETS"),
            columnDef: "targets",
            width: 50,
            visible: true,
            component: RolesPermissionsColumnComponent,
            assignComponentsInputs: (compRef: ComponentRef<RolesPermissionsColumnComponent>, role: Role) => {
                const componentInstance = compRef.instance;
                componentInstance.view = role._frontData.targets_read;
                componentInstance.edit = role._frontData.targets_write;
                componentInstance.notify = role._frontData.targets_notify;
            }
        },
        {
            header: this.translate.instant("REPORTS"),
            columnDef: "reports",
            width: 50,
            visible: true,
            component: RolesPermissionsColumnComponent,
            assignComponentsInputs: (compRef: ComponentRef<RolesPermissionsColumnComponent>, role: Role) => {
                const componentInstance = compRef.instance;
                componentInstance.view = role._frontData.reports_read;
                componentInstance.edit = role._frontData.reports_write;
                componentInstance.notify = role._frontData.reports_notify;
            }
        }
    ];

    private tourSteps = TourSteps.roleList;

    constructor(
        private router: Router,
        private tps: RolesService,
        private modalService: ModalService,
        private rolesService: RolesService,
        public mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService,
        public tourService: TourService
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                if (event.snapshot.params && event.snapshot.params.id) {
                    this.selectedRoleID = parseInt(event.snapshot.params.id, 10);
                    const role = this.roles.find(role => role.id === this.selectedRoleID);
                    this.selectedRows = role ? [role] : [];
                } else {
                    // Set Title
                    this.titleService.setTitle("ROLES", "");
                    this.selectedRoleID = null;
                    this.selectedRows = [];
                }
            });
    }

    ngOnInit() {
        this.loading = true;

        // local storage
        document.getElementById("left-container").style.flexBasis = localStorage.getItem("list-panel-width");

        this.rolesSubscription = this.tps.roles.subscribe(roles => {
            this.roles = roles;
            if (this.roles) {
                this.rolesBS$.next([...roles]);
                this.loading = false;
                if (this.selectedRoleID) {
                    const role = this.roles.find(role => role.id === this.selectedRoleID);
                    this.selectedRows = role ? [role] : [];
                }
            }
        });

        setTimeout(() => {
            if (this.selectedRoleID) {
                const role = this.roles.find(role => role.id === this.selectedRoleID);
                this.selectedRows = role ? [role] : [];
            }
        });

        this.tourService.initialize(this.tourSteps);
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.rolesSubscription.unsubscribe();
    }

    selectRow = (role: Role) => {
        this.selectedRoleID = role.id;
        this.router.navigate([Constants.urls.accountManagement.roles, role.id]);
    };

    async refresh() {
        this.refreshing = true;
        let role = null;
        const roles = this.tps.refreshRoles(true).toPromise();
        if (this.selectedRoleID) {
            role = this.rolesService.refreshRole(this.selectedRoleID).toPromise();
        }
        await Promise.all([roles, role]);
        this.refreshing = false;
    }

    async multiAction(action: string, func: (role: Role) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "ROLE", this.selectedRows, func);
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple roles");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (role: Role) => this.rolesService.deleteRole(role));
    }

    get roles$() {
        return this.rolesBS$.asObservable();
    }
}
